



import { Component, Vue } from "vue-property-decorator";
import UserDetailsList from "./userDetailsList/UserDetailsList.vue";

@Component({
  components: {
    UserDetailsList
  }
})

export default class ProfilAdmin extends Vue {

}
