

































































































import { BPagination, BSpinner, BFormCheckbox } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BSpinner,
    BPagination,
    BFormCheckbox
  },
})
export default class UserDetailsList extends Vue {
  listUserDetails: PaginatedList<UserDetail> = new PaginatedList<UserDetail>();

  currentPage = 1
  pageSize = 20

  ssieInput: string = "";
  nomInput: string = "";
  prenomInput: string = "";
  identifiantInput: string = "";

  timeout: any;

  mainLoading: boolean = false;

  onlyActif: boolean = false;
  
  async created() {
    await this.loadUserDetailsList();
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadUserDetailsList()
  }

  @Watch("ssieInput")
  @Watch("nomInput")
  @Watch("prenomInput")
  @Watch("identifiantInput")
  async search() {
    clearTimeout(this.timeout);
    setTimeout(async () => {
      await this.loadUserDetailsList();
    }, 3000)
  }
  
  async loadUserDetailsList() {
    this.mainLoading = true;

    await this.$http.ressifnet.userDetails
      .paginatedList(
        this.currentPage,
        this.pageSize,
        this.nomInput,
        this.prenomInput,
        this.ssieInput,
        this.identifiantInput,
        this.onlyActif
      )
      .then(
        (response: PaginatedList<UserDetail>) => {
           this.listUserDetails = response;
           this.mainLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des utilisateurs",
          });
        }
      );
  }
}
